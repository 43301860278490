<template>
  <div>
    <b-card>
      <b-button variant="flat-secondary" @click="addGroupClicked">
        <feather-icon icon="PlusIcon" class="text-success" />
        Add Group
      </b-button>
      <b-table
        :items="groups"
        :fields="groupFields"
        :sort-by.sync="groupSortBy"
        :sort-desc.sync="groupSortDesc"
        responsive="sm"
        selectable
        select-mode="single"
        selected-variant="dark"
        :current-page="currentGroupPage"
        :per-page="perPage"
        @row-selected="onGroupRowSelected"
      />
      <b-pagination
        v-model="currentGroupPage"
        :total-rows="groupRowCount"
        :per-page="perPage"
      />
    </b-card>
    <div v-if="selectedGroup">
      <admin-group :key="selectedGroup.id" :selected-group="selectedGroup" :all-users="allUsers" @closed="closeGroup" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminGroup from './AdminGroupEdit.vue'

const scrollToEnd = () => {
  setTimeout(() => window.scrollTo(9999, 9999), 100)
}

export default {
  name: 'AdminGroupsTable',
  components: {
    AdminGroup,
  },
  data() {
    return {
      groupFields: [{ key: 'name', sortable: true }],
      groupSortBy: 'name',
      groupSortDesc: false,
      selectedGroup: null,
      currentGroupPage: 1,
      perPage: 8,
    }
  },
  computed: {
    ...mapGetters({
      allUsers: 'auth/allUsers',
      groups: 'auth/allGroups',
    }),
    groupRowCount() {
      return this.groups.length
    },
  },
  async mounted() {
    await this.$store.dispatch('auth/getAllUsers')
    await this.$store.dispatch('auth/getAllGroups')
    await this.$store.dispatch('auth/getAllRoles')
  },
  methods: {
    closeGroup() {
      this.selectedGroup = null
    },
    addGroupClicked() {
      this.selectedGroup = { id: 'new' }
      scrollToEnd()
    },
    onGroupRowSelected(items) {
      this.selectedGroup = null
      if (items.length > 0) {
        [this.selectedGroup] = items
        scrollToEnd()
      }
    },
  },
}
</script>
