<template>
  <b-card>
    <b-button class="btn-close" variant="flat-secondary" size="lg" @click="$emit('closed')">
      &times;</b-button>
    <b-form class="mt-0">
      <b-form-row>
        <b-form inline>
          <b-form-group label="Group Name">
            <b-form-input id="group-name-field" v-model="selectedGroup.name" />
          </b-form-group>
        </b-form>
      </b-form-row>
      <b-form-row v-if="!isNewGroup" class="mt-1">
        <b-col class="col-md-5">
          <b-form-group label="Available Users">
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="user-search"
                v-model="allUserSearchText"
                class="form-control-merge"
                name="user-search"
                placeholder="Search users"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-select v-model="selected" :options="availableUsers" :value-field="'id'" :text-field="'username'" multiple :select-size="8" />
          </b-form-group>
        </b-col>
        <b-col class="col-md-2">
          <div class="flex-column btn-col">
            <b-button class="m-1" variant="outline-primary" @click="addToGroupClicked">
              <font-awesome-icon :icon="['fa', 'fa-arrow-right']" />
            </b-button>
            <b-button class="m-1" variant="outline-primary" @click="removeFromGroupClicked">
              <font-awesome-icon :icon="['fa', 'fa-arrow-left']" />
            </b-button>
          </div>
        </b-col>
        <b-col class="col-md-5">
          <b-form-group label="Group's Current Users">
            <b-form-select v-model="selected" :options="groupUsers" :value-field="'id'" :text-field="'username'" multiple :select-size="12" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row v-if="!isNewGroup" class="mt-1">
        <b-col class="col-md-5">
          <b-form-group label="Available Roles">
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                id="user-search"
                v-model="allRolesSearchText"
                class="form-control-merge"
                name="user-search"
                placeholder="Search users"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                />
              </b-input-group-append>
            </b-input-group>
            <b-form-select v-model="selected" :options="availableRoles" :value-field="'id'"
                           :text-field="'name'" multiple :select-size="8"
            />
          </b-form-group>
        </b-col>
        <b-col class="col-md-2">
          <div class="flex-column btn-col">
            <b-button class="m-1" variant="outline-primary" @click="addRoleToGroupClicked">
              <font-awesome-icon :icon="['fa', 'fa-arrow-right']" />
            </b-button>
            <b-button class="m-1" variant="outline-primary" @click="removeRoleFromGroupClicked">
              <font-awesome-icon :icon="['fa', 'fa-arrow-left']" />
            </b-button>
          </div>
        </b-col>
        <b-col class="col-md-5">
          <b-form-group label="Group's Current Roles">
            <b-form-select v-model="selected" :options="groupRoles" :value-field="'id'"
                           :text-field="'name'" multiple :select-size="12"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-1">
        <b-button v-if="isNewGroup" variant="primary" @click="saveNewGroup">
          Create</b-button>
        <b-button v-if="!isNewGroup" variant="primary" @click="saveEditedGroup">
          Save</b-button>
        <b-button v-if="!isNewGroup" class="ml-1" variant="outline-danger" @click="deleteGroup">
          Delete Group</b-button>
      </b-form-row>
    </b-form>
  </b-card>
</template>

<script>
export default {
  name: 'AdminGroup',
  props: {
    selectedGroup: { type: Object, default: null },
    allUsers: { type: Array, default: null },
  },
  data() {
    return {
      selected: [''],
      options: [],
      groupUsers: [],
      groupRoles: [],
      allUserSearchText: '',
      allRolesSearchText: '',
    }
  },
  computed: {
    isNewGroup() {
      return this.selectedGroup.id === 'new'
    },
    availableUsers() {
      return this.allUsers.filter(ag => !this.groupUsers.map(ug => ug.id).includes(ag.id)
        && (this.allUserSearchText === ''
          || ag.username.toLowerCase().includes(this.allUserSearchText.toLowerCase())))
    },
    availableRoles() {
      return this.$store.state.auth.allRoles.filter(ag => !this.groupRoles.map(ug => ug.id).includes(ag.id)
        && (this.allRolesSearchText === ''
          || ag.name.toLowerCase().includes(this.allRolesSearchText.toLowerCase())))
    },
  },
  async mounted() {
    if (this.selectedGroup.id !== 'new') {
      this.groupUsers = await this.$coreService.groupManagementApi.getGroupMembers(this.selectedGroup.id)
      const groupRolesResponse = await this.$coreService.groupManagementApi.getGroupRoles(this.selectedGroup.id)
      this.groupRoles = groupRolesResponse && groupRolesResponse.realmMappings ? groupRolesResponse.realmMappings : []
    }
  },
  methods: {
    async deleteGroup() {
      const opts = {
        centered: true,
        okVariant: 'danger',
      }
      const response = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the group ${this.selectedGroup.name}?`, opts)
      if (response) {
        await this.$coreService.groupManagementApi.deleteGroup(this.selectedGroup.id)
      }
    },
    async addToGroupClicked() {
      const addingUser = this.availableUsers.find(ag => ag.id === this.selected[0])
      if (addingUser) {
        const response = await this.$coreService.userManagementApi.addGroupToUser(addingUser.id, this.selectedGroup.id)
        this.groupUsers.push(addingUser)
      }
    },
    async removeFromGroupClicked() {
      const removingUser = this.groupUsers.find(ug => ug.id === this.selected[0])
      if (removingUser) {
        await this.$coreService.userManagementApi.removeUserGroup(removingUser.id, this.selectedGroup.id)
        this.groupUsers = this.groupUsers.filter(ug => ug.id !== removingUser.id)
      }
    },
    async addRoleToGroupClicked() {
      const adding = this.availableRoles.filter(ag => this.selected.includes(ag.id))
      if (adding) {
        const arrayOfNames = adding.map(r => r.name)
        const fn = () => `["${arrayOfNames.join('","')}"]`
        await this.$coreService.groupManagementApi.addGroupRoles(this.selectedGroup.id, arrayOfNames, { transformRequest: [fn] })
        this.groupRoles = this.groupRoles.concat(adding)
      }
    },
    async removeRoleFromGroupClicked() {
      const removing = this.groupRoles.filter(ug => this.selected.includes(ug.id))
      if (removing) {
        await this.$coreService.groupManagementApi.removeGroupRoles(this.selectedGroup.id, removing.map(ag => ag.name))
        this.groupRoles = this.groupRoles.filter(ug => !removing.find(ur => ur.id === ug.id))
      }
    },
    async saveNewGroup() {
      const response = await this.$coreService.groupManagementApi.createGroup(this.selectedGroup.name)
      this.selectedGroup = { ...this.selectedGroup, ...response }
      this.$store.commit('auth/storeGroup', this.selectedGroup)
    },
    async saveEditedGroup() {
      const editedGroup = this.selectedGroup
      const response = await this.$coreService.groupManagementApi.updateGroup(this.selectedGroup.id, editedGroup)
      this.selectedGroup = { ...this.selectedGroup, ...response }
      this.$store.commit('auth/storeGroup', this.selectedGroup)
    },
  },
}
</script>

<style scoped>
.btn-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.btn-close {
    margin: 0.2em 0.5em 0 0;
    padding: 0.2em;
    position: absolute;
    right: 0;
    top: 0;
    font-size: xx-large;
    z-index: 9999;
}
</style>
